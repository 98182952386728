<template>
  <div class="app-popup-card card flat row p-2 xs-mb-1 mb-2">
    <!-- Loading State -->
    <template v-if="isLoading">
      <div
        class="skeleton popup-thumbnail h-100 xs-col-12 sm-col-4 md-col-3 lg-col-2"
      ></div>
      <div class="xs-col-12 sm-col-8 md-col-9 lg-col-10 sm-pl-2">
        <div class="xs-mt-2 mb-1">
          <span class="skeleton d-inline-block popup-title w-50"> </span>
          <span class="skeleton d-inline-block popup-date w-10 md-ml-2"></span>
        </div>
        <span class="skeleton d-block w-20 mb-2"></span>
        <span class="skeleton btn px-3"> </span>
        <span class="skeleton btn px-3 ml-1"></span>
      </div>
    </template>
    <!-- Non-Loading State -->
    <template v-else>
      <div class="col-12 d-flex align-items-center">
        <fd-button v-if="sortable" class="handle flat circle mr-2">
          <i class="fas fa-grip-lines"></i>
        </fd-button>
        <div class="row w-100">
          <img
            class="popup-thumbnail cursor-pointer xs-col-12 sm-col-4 md-col-3 lg-col-2"
            :src="$getFileURL(popup.image[0])"
            :alt="popup.title"
            @click="$emit('view-image', $getFileURL(popup.image[0]))"
          />
          <div class="xs-col-12 sm-col-8 md-col-9 lg-col-10 sm-pl-2">
            <div class="xs-mt-2 mb-1">
              <span class="popup-title sm-col-12"> {{ popup.title }} </span>
              <span class="popup-date ml-1">{{
                $moment(popup.createdAt).format("DD-MM-YYYY")
              }}</span>
            </div>
            <!-- Type & Status -->
            <div class="mb-2">
              <chip>{{ popup.type }}</chip>
              <chip v-if="popup.isActive" class="bg-success fg-white ml-2">
                Active
              </chip>
            </div>
            <!-- Actions -->
            <fd-button
              class="px-3"
              :class="{ main: !popup.isActive, danger: popup.isActive }"
              @click="setPopupIsActive"
            >
              <i class="fas fa-times mr-1" v-if="popup.isActive"></i>
              <i class="fas fa-check mr-1" v-else></i>
              {{ popup.isActive ? "Deactivate" : "Activate" }}
            </fd-button>
            <fd-button
              class="bordered main px-3 ml-1"
              @click="$emit('edit', popup)"
            >
              Edit
            </fd-button>
            <fd-button
              class="bordered danger px-3 ml-1"
              @click="$emit('delete', popup.id)"
              >Delete</fd-button
            >
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  components: {
    Chip: () => import("@/components/GlobalComponents/Chip")
  },
  mixins: [],
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    popup: {
      type: Object,
      default: () => ({})
    },
    sortable: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {
    isActive() {
      return this.popup.isActive;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    async setPopupIsActive() {
      let isActive = !this.popup.isActive;
      let activateContent = {
        title: "Activate Popup",
        message: `Are you sure you want to activate ${this.popup.title}? The current active popup will be deactivated.`,
        confirmText: "Activate"
      };
      let deactivateContent = {
        type: "alert",
        title: "Deactivate Popup",
        message: `Are you sure you want to deactivate ${this.popup.title}?`,
        confirmText: "Deactivate"
      };

      let modalContext = isActive ? activateContent : deactivateContent;

      let confirm = await this.$confirm(modalContext);

      if (confirm) {
        this.$emit("set-is-active", this.popup.id, isActive);
      }
    }
  }
};
</script>

<style lang="scss">
.app-popup-card {
  .popup-thumbnail {
    $width: 250px;
    width: $width;
    aspect-ratio: 16/9;
    height: $width * 9 / 16;
    object-fit: cover;
    border-radius: 5px;
  }
  .popup-title {
    font-size: 18px;
  }
  .popup-date {
    color: #777;
  }
}
</style>
